<template>
  <div class="app">
		<div class="btn-box">
			<el-link :underline="false" class="btn" type="primary" size="mini" @click="changeLang">{{i18nlocale=='en'?'语言：简体中文':'Language：English' }}</el-link >
			</div>
    <div class="content">
      <div class="content-left">
        <h2>
          <img src="@a/header/logo.png"  @click="goIndex" alt="" />
        </h2>
        <div class="desc"  v-if="i18nlocale=='zh'">
          <p>复合涂层打印材料生产、销售</p>
          <p>双色商务咨询：15609292022（问西航）</p>
          <!-- <p>特种材料咨询：13516191318（张敏操）</p> -->
        </div>

				<div v-else class="desc">
					<p>Contact: Sammy Wei</p>
					<p>Email: weixiaoyun@kymasf.com</p>
				  <p>Tel: +86-13761099834</p>
				</div>

      </div>
	  <div class="content-right">

		  <el-menu class="el-menu-demo" mode="horizontal" router ref='menu' :default-active='$route.path'>
			<el-menu-item :index="$route.params.language?'/'+i18nlocale:'/'">{{ $t('m.home') }}</el-menu-item>
			<el-submenu index="2" class="el-menu-ul">
			  <template slot="title">{{ $t('m.products') }}</template>
			  <el-menu-item :index="'/material/'+item.value+'/'+i18nlocale" v-for="(item,index) in menuList" :key="index" style="overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;">{{item.name}}</el-menu-item>
			</el-submenu>
			<el-menu-item :index="'/platform'+'/'+i18nlocale">{{ $t('m.LabelDesign') }}</el-menu-item>
			<el-menu-item :index="'/cloudPrint'+'/'+i18nlocale">{{ $t('m.cloudPrint') }}</el-menu-item>
		  </el-menu>
	  </div>
      <!-- <div class="content-right">
        <img src="@a/header/phone.png" alt />
        <div class="phone">
          <p>7*24小时服务热线</p>
          <p>020-00000000</p>
        </div>
        <div class="btns">
          <span @click="changeLanguage('zh')">中</span>
          <el-divider direction="vertical"></el-divider>
          <span @click="changeLanguage('en')">en</span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import i18n from "@/lang"
export default {
	data(){
		return {
			// menuList:[
			// 	{value:'paperBicolor',name:'双色热敏纸'},
			// 	{value:'paperCopper',name:'热敏铜版纸'},
			// 	{value:'paperFourproofing',name:'四防热敏纸'},
			// 	{value:'washHigh',name:'热敏高温水洗唛'},
			// 	{value:'paperCompound',name:'热敏合成纸'},
			// 	{value:'washNormal',name:'热敏常温水洗唛'},
			// ]
		}
	},
	computed:{
		i18nlocale(){
			return i18n.locale
		},
		menuList(){
		return [
				{value:'paperBicolor',name:this.$t('m.DualColorPaper')},
				{value:'paperCopper',name:this.$t('m.ThermalPaper')},
				{value:'paperFourproofing',name:this.$t('m.UltrarPaper')},
				// {value:'washHigh',name:this.$t('m.HighWashLabel')},
				{value:'paperCompound',name:this.$t('m.SyntheticPaper')},
				// {value:'washNormal',name:this.$t('m.NormalWashLabel')},
			]
		}
  },
	watch: {
		$route() {
			let i = this.$route.path;
			setTimeout(() => { //路由跳转
				this.$refs.menu.activeIndex = i
			}, 100)
		}
	},
  methods:{
    changeLanguage(){
    },
		changeLang(){
      if (i18n.locale=='zh'){
        i18n.locale='en'
      }else if (i18n.locale=='en'){
        i18n.locale='zh'
      }

		 this.$router.push({ name: this.$route.name, params: { language:i18n.locale } });


    },
		goIndex(){
			this.$router.push('/')
		}
  }
};
</script>

<style lang='less' scoped>
.app {
  background: #fff;
	border-bottom: 2px solid #efefef;
  .content {
    width: 1200px;
    margin: 20px auto 20px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    .content-left {
      display: flex;
      align-items: center;
      h2 {
        margin-right: 30px;
        img {
          // width: 102px;
          height: 59px;
          image-rendering: -webkit-optimize-contrast;
        }
      }
      .desc {
        border-left: 1px solid #ccc;
        padding-left: 30px;
        p {
          &:nth-child(1) {
            font-size: 16px;
            color: rgb(30, 80, 174);
            font-weight: bold;
          }
          &:nth-child(2) {
            font-size: 16px;
            color: rgb(166, 166, 166);
          }
          &:nth-child(3) {
            font-size: 16px;
            color: rgb(166, 166, 166);
          }
        }
      }
    }
    .content-right {
      display: flex;
      align-items: center;
      img {
        height: 42px;
        margin-right: 20px;
      }
      .phone {
        p {
          &:nth-child(2) {
            font-size: 22px;
            color: rgb(30, 80, 174);
          }
        }
      }
      .btns {
        margin-left: 35px;
        span {
          cursor: pointer;
        }
        i {
          margin: 0 5px;
        }
      }
    }
  }
	.btn-box{
		position: absolute;
		// position: fixed;
		top:0;
		right: 0;
		z-index: 99;
	}
}
.el-menu.el-menu--horizontal{
	border: none;
}
.el-menu .el-menu-item:hover{
    outline: 0 !important;
    color: #2D6CFF !important;
}
.el-submenu /deep/.el-submenu__title:hover {
  color: #2D6CFF !important;
}
</style>
